<template>
  <v-card>
    <v-card-text>
      <h3>
        {{ $t('advancedSettings.makePublicLinkAvailable') }}
      </h3>
      <p>
        {{ $t('advancedSettings.makePublicLinkAvailableDescription') }}
      </p>
      <p class="integration-routes-text">
        <v-switch
          v-model="publicLinkEnabled"
          color="green"
          hide-details
          @change="toggleOrganisationPublicRoutes"></v-switch>
      </p>
      <div v-if="publicLinkEnabled && publicLink">
        <p class="pt-3 d-flex align-center" style="word-break: break-all;">
          <span style="padding-bottom: 5px; font-size: 12px">
            {{ publicLink }}
          </span>
          <v-icon class="ml-5" large @click="copyToClipboard">mdi-content-copy</v-icon>
        </p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import i18n from '@/i18n/i18n';

export default {
  name: 'PublicLink',
  props: ['publicLinkOld'],
  components: {},
  data: () => ({
    publicLinkEnabled: false,
    publicLink: '',
  }),
  created() {},
  methods: {
    async toggleOrganisationPublicRoutes() {
      await this.$store
        .dispatch('organisations/toggleOrganisationPublicLink')
        .then((res) => {
          if (res?.data?.public_booking_id) {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.makePublicLinkActive'), color: 'green' });

            this.publicLink =
              process.env.VUE_APP_PUBLIC_DOMAIN + '/public/reservation-form?param=' + res?.data?.public_booking_id;
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.makePublicLinkInactive'), color: 'green' });

            this.publicLink = '';
          }
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        });
    },

    async copyToClipboard() {
      try {
        await navigator.clipboard.writeText(this.publicLink);
        this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.publicLinkCopied'), color: 'green' });
      } catch (err) {
        this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.copyPublicLink'), color: 'red' });
      }
    },
  },
  computed: {},
  watch: {
    publicLinkOld(val) {
      if (val) {
        this.publicLinkEnabled = true;

        this.publicLink = process.env.VUE_APP_PUBLIC_DOMAIN + '/public/reservation-form?param=' + this.publicLinkOld;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.integration-routes-text {
  display: flex;
  align-items: baseline;
  font-size: 16px;
}

.v-card {
  height: 100%;
}

.gnet-wrapper {
  position: relative;

  i {
    position: absolute;
    right: 20px;
    top: 40%;
    transform: translateY(-40%);
  }
}
</style>
