<template>
  <v-container class="px-sm-16">
    <v-row>
      <v-col cols="12" lg="12" md="12" sm="12">
        <h2 class="my-5">{{ $t('advancedSettings.advancedSettings') }}</h2>

        <validation-observer ref="form" v-slot="{ handleSubmit, failed }">
          <v-form lazy-validation @submit.prevent="handleSubmit(save)">
            <v-row>
              <v-col cols="12" xl="4" lg="6" md="6" sm="6">
                <v-card>
                  <v-card-text>
                    <h3>{{ $t('advancedSettings.distanceUnit') }}</h3>
                    <p>{{ $t('advancedSettings.distanceUnitDescription') }}</p>
                    <v-radio-group v-model="advancedSettings.distance_unit" column>
                      <v-radio :label="$t('advancedSettings.kilometers')" value="km"></v-radio>
                      <v-radio :label="$t('advancedSettings.miles')" value="mi"></v-radio>
                    </v-radio-group>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" xl="4" lg="6" md="6" sm="6">
                <v-card>
                  <v-card-text>
                    <h3>{{ $t('advancedSettings.dateFormat') }}</h3>
                    <p>{{ $t('advancedSettings.dateFormatDescription') }}</p>
                    <v-radio-group v-model="advancedSettings.date_format" column>
                      <v-radio :label="$t('advancedSettings.ddmmyyyy')" value="dd-mm"></v-radio>
                      <v-radio :label="$t('advancedSettings.mmddyyyy')" value="mm-dd"></v-radio>
                    </v-radio-group>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" xl="4" lg="6" md="6" sm="6">
                <v-card>
                  <v-card-text>
                    <h3>
                      {{ $t('advancedSettings.mandatoryDrivings') }}
                    </h3>
                    <p>
                      {{ $t('advancedSettings.mandatoryDrivingsDescription') }}
                    </p>
                    <v-checkbox
                      v-model="advancedSettings.drivings_mandatory"
                      :label="$t('advancedSettings.mandatoryDrivingsDefault')"></v-checkbox>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" xl="4" lg="6" md="6" sm="6">
                <v-card>
                  <v-card-text>
                    <h3>
                      {{ $t('advancedSettings.paidDrivings') }}
                    </h3>
                    <p>
                      {{ $t('advancedSettings.paidDrivingsDescription') }}
                    </p>
                    <v-checkbox
                      v-model="advancedSettings.drivings_paid"
                      :label="$t('advancedSettings.paidDrivingsDefault')"></v-checkbox>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" xl="4" lg="6" md="6" sm="6">
                <v-card>
                  <v-card-text>
                    <h3>
                      {{ $t('advancedSettings.emailsEnabled') }}
                    </h3>
                    <p>
                      {{ $t('advancedSettings.emailsEnabledDescription') }}
                    </p>
                    <v-checkbox
                      v-model="advancedSettings.emails_enabled"
                      :label="$t('advancedSettings.emailsEnabledDefault')"></v-checkbox>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" xl="4" lg="6" md="6" sm="6">
                <v-card>
                  <v-card-text>
                    <h3>
                      {{ $t('advancedSettings.passengerReviewsEnabled') }}
                    </h3>
                    <p>
                      {{ $t('advancedSettings.passengerReviewsEnabledDescription') }}
                    </p>
                    <v-checkbox
                      v-model="advancedSettings.passenger_reviews_enabled"
                      :label="$t('advancedSettings.passengerReviewsEnabledDefault')"></v-checkbox>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" xl="4" lg="6" md="6" sm="12">
                <v-card>
                  <v-card-text>
                    <h3>
                      {{ $t('advancedSettings.notificationsEmail') }}
                    </h3>
                    <p>
                      {{ $t('advancedSettings.notificationsEmailDescription') }}
                    </p>
                    <validation-provider rules="email|max:200" v-slot="{ errors }" name="email" ref="emailRef">
                      <v-text-field
                        type="email"
                        name="email"
                        v-model="advancedSettings.email_for_notifications"
                        clearable
                        counter="200"
                        :label="$t('advancedSettings.email')"
                        :error-messages="errors"></v-text-field>
                    </validation-provider>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" xl="4" lg="6" md="6" sm="12">
                <v-card>
                  <v-card-text>
                    <h3>
                      {{ $t('advancedSettings.vatPercentage') }}
                    </h3>
                    <p>
                      {{ $t('advancedSettings.vatPercentageDescription') }}
                    </p>
                    <validation-provider rules="numeric_decimal|max:200" v-slot="{ errors }" name="vat">
                      <v-text-field
                        type="text"
                        name="vat"
                        v-model="advancedSettings.vat_percentage"
                        clearable
                        counter="200"
                        :label="$t('advancedSettings.vat')"
                        :error-messages="errors"></v-text-field>
                    </validation-provider>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col
                cols="12"
                xl="4"
                lg="6"
                md="6"
                sm="12"
                v-if="$store.getters['auth/user']?.organisation?.country.code === 'ME'">
                <v-card>
                  <v-card-text>
                    <h3 class="mb-3">
                      {{ $t('advancedSettings.articlesOfLaw') }}
                    </h3>

                    <validation-provider rules="" v-slot="errors" name="articles_of_law">
                      <vue-editor
                        v-model="advancedSettings.law_article"
                        :placeholder="$t('advancedSettings.articlesOfLaw')">
                      </vue-editor>
                      <div v-if="errors.errors[0]" v class="v-text-field__details">
                        <div class="v-messages theme--light error--text" role="alert">
                          <div class="v-messages__wrapper">
                            <div class="v-messages__message">
                              {{ errors.errors[0] }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </validation-provider>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" xl="4" lg="6" md="6" sm="12">
                <v-card>
                  <v-card-text>
                    <h3 class="mb-3">
                      {{ $t('advancedSettings.invoicePaymentInstructions') }}
                    </h3>

                    <validation-provider rules="" v-slot="errors" name="articles_of_law">
                      <vue-editor
                        v-model="advancedSettings.invoice_payment_instructions"
                        :placeholder="$t('advancedSettings.invoicePaymentInstructions')">
                      </vue-editor>
                      <div v-if="errors.errors[0]" v class="v-text-field__details">
                        <div class="v-messages theme--light error--text" role="alert">
                          <div class="v-messages__wrapper">
                            <div class="v-messages__message">
                              {{ errors.errors[0] }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </validation-provider>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" lg="12" md="12" sm="12" class="text-end">
                <button-submit :failed="failed" :loading="loading" buttonText="buttons.save"></button-submit>
              </v-col>
            </v-row>
          </v-form>
        </validation-observer>
      </v-col>

      <v-col cols="12" lg="12" md="12" sm="12">
        <h2 class="my-5">{{ $t('advancedSettings.integrations') }}</h2>

        <validation-observer ref="formGnet" v-slot="{ handleSubmit, failed }">
          <v-form lazy-validation @submit.prevent="handleSubmit(saveGnetKey)">
            <v-row>
              <v-col cols="12" xl="4" lg="6" md="6" sm="12">
                <v-card>
                  <v-card-text>
                    <h3>
                      {{ $t('advancedSettings.gnetId') }}
                    </h3>
                    <p>
                      {{ $t('advancedSettings.gnetDescription') }}
                    </p>
                    <div class="gnet-wrapper">
                      <validation-provider rules="required|max:100" v-slot="{ errors }" name="gnet_id" ref="gnetIdRef">
                        <v-text-field
                          v-model="advancedSettings.gnet_id"
                          name="gnet_id"
                          counter="100"
                          clearable
                          :label="$t('advancedSettings.gnetId')"
                          :disabled="gnetIdValid !== '' && gnetIdValid"
                          :error-messages="errors"></v-text-field>
                        <v-icon v-if="gnetIdValid !== '' && !gnetIdValid" color="red"> mdi-alert-circle </v-icon>
                        <v-icon v-if="gnetIdValid !== '' && gnetIdValid" color="green"> mdi-check-circle </v-icon>
                      </validation-provider>
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-img
                      alt="GNET"
                      class="shrink ml-1"
                      contain
                      src="@/assets/gnet.png"
                      transition="scale-transition"
                      width="78px"
                      height="31px" />
                    <v-spacer></v-spacer>
                    <button-submit
                      :failed="failed || (gnetIdValid !== '' && gnetIdValid)"
                      :loading="loading2"
                      buttonText="buttons.save"></button-submit>
                  </v-card-actions>
                </v-card>
              </v-col>

              <v-col cols="12" xl="4" lg="6" md="6" sm="12">
                <v-card>
                  <v-card-text>
                    <h3>
                      {{ $t('advancedSettings.stripe') }}
                    </h3>
                    <p>
                      {{ $t('advancedSettings.stripeDescription') }}
                    </p>
                  </v-card-text>

                  <v-card-actions>
                    <svg
                      viewBox="0 0 60 25"
                      xmlns="http://www.w3.org/2000/svg"
                      width="60"
                      height="25"
                      alt="Stripe logo">
                      <title>Stripe logo</title>
                      <path
                        fill="var(--userLogoColor, #0A2540)"
                        d="M59.64 14.28h-8.06c.19 1.93 1.6 2.55 3.2 2.55 1.64 0 2.96-.37 4.05-.95v3.32a8.33 8.33 0 0 1-4.56 1.1c-4.01 0-6.83-2.5-6.83-7.48 0-4.19 2.39-7.52 6.3-7.52 3.92 0 5.96 3.28 5.96 7.5 0 .4-.04 1.26-.06 1.48zm-5.92-5.62c-1.03 0-2.17.73-2.17 2.58h4.25c0-1.85-1.07-2.58-2.08-2.58zM40.95 20.3c-1.44 0-2.32-.6-2.9-1.04l-.02 4.63-4.12.87V5.57h3.76l.08 1.02a4.7 4.7 0 0 1 3.23-1.29c2.9 0 5.62 2.6 5.62 7.4 0 5.23-2.7 7.6-5.65 7.6zM40 8.95c-.95 0-1.54.34-1.97.81l.02 6.12c.4.44.98.78 1.95.78 1.52 0 2.54-1.65 2.54-3.87 0-2.15-1.04-3.84-2.54-3.84zM28.24 5.57h4.13v14.44h-4.13V5.57zm0-4.7L32.37 0v3.36l-4.13.88V.88zm-4.32 9.35v9.79H19.8V5.57h3.7l.12 1.22c1-1.77 3.07-1.41 3.62-1.22v3.79c-.52-.17-2.29-.43-3.32.86zm-8.55 4.72c0 2.43 2.6 1.68 3.12 1.46v3.36c-.55.3-1.54.54-2.89.54a4.15 4.15 0 0 1-4.27-4.24l.01-13.17 4.02-.86v3.54h3.14V9.1h-3.13v5.85zm-4.91.7c0 2.97-2.31 4.66-5.73 4.66a11.2 11.2 0 0 1-4.46-.93v-3.93c1.38.75 3.1 1.31 4.46 1.31.92 0 1.53-.24 1.53-1C6.26 13.77 0 14.51 0 9.95 0 7.04 2.28 5.3 5.62 5.3c1.36 0 2.72.2 4.09.75v3.88a9.23 9.23 0 0 0-4.1-1.06c-.86 0-1.44.25-1.44.9 0 1.85 6.29.97 6.29 5.88z"
                        fill-rule="evenodd"></path>
                    </svg>
                    <v-spacer></v-spacer>
                    <v-btn
                      :disabled="loading3"
                      :loading="loading3"
                      :class="advancedSettings.stripe_connect_enabled == 1 ? 'primary red' : 'primary'"
                      text
                      type="button"
                      @click="toggleStripeConnect">
                      {{ advancedSettings.stripe_connect_enabled ? $t('buttons.deactivate') : $t('buttons.activate') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>

              <v-col cols="12" xl="4" lg="6" md="12" sm="12">
                <integration-routes :integrationTokenOld="integrationToken" />
              </v-col>

              <v-col cols="12" xl="4" lg="6" md="12" sm="12">
                <public-link :publicLinkOld="publicLink" />
              </v-col>
            </v-row>
          </v-form>
        </validation-observer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import { VueEditor, Quill } from 'vue2-editor';
import { ImageDrop } from 'quill-image-drop-module';
import ImageResize from 'quill-image-resize-vue';
import IntegrationRoutes from '@/components/advanced-settings/IntegrationRoutes.vue';
import PublicLink from '@/components/advanced-settings/PublicLink.vue';

Quill.register('modules/imageDrop', ImageDrop);
Quill.register('modules/imageResize', ImageResize);

export default {
  name: 'UserOrganisationPage',
  components: { ButtonSubmit, VueEditor, IntegrationRoutes, PublicLink },
  data: () => ({
    advancedSettings: {
      distance_unit: 'km',
      date_format: 'dd-mm',
      drivings_mandatory: false,
      drivings_paid: false,
      emails_enabled: false,
      email_for_notifications: '',
      vat_percentage: '',
      gnet_id: '',
      law_article: '',
      invoice_payment_instructions: '',
      stripe_connect_enabled: false,
    },
    gnetExists: false,
    gnetIdValid: '',
    integrationToken: false,
    publicLink: false,
    loading: false,
    loading2: false,
    loading3: false,
  }),
  created() {
    this.loadAdvancedSettings();

    // if (this.$route.query['stripe-connect'] == 'true') {}
    if (this.$route.query['stripe-connect'] == 'false') {
      this.$store.dispatch('showSnackbar', {
        text: i18n.t('snackbar.finishStripeConnect'),
        color: '#ffeb3b',
        textColor: '#000',
      });
    }
  },
  methods: {
    async loadAdvancedSettings() {
      await this.$store.dispatch('articlesOfLaw/getAllAdvancedSettingsForOrganization').then((res) => {
        this.advancedSettings = {
          ...res.data,
          distance_unit: res.data.distance_unit || 'km',
          date_format: res.data.date_format || 'dd-mm',
          drivings_mandatory: parseInt(res.data.drivings_mandatory) || false,
          drivings_paid: parseInt(res.data.drivings_paid) || false,
          emails_enabled: parseInt(res.data.emails_enabled) || false,
          passenger_reviews_enabled: parseInt(res.data.passenger_reviews_enabled) || false,
          stripe_connect_enabled: parseInt(res.data.stripe_connect_enabled) || false,
        };

        if (res.data.gnet_id) {
          this.gnetExists = true;

          this.checkIfGnetIsValid();
        }

        this.integrationToken = res.data.api_auth_token;
        this.publicLink = res.data.public_booking_id;

        this.updateAdvancedSettings(res.data);
      });
    },

    createFormData() {
      let formData = new FormData();

      formData.append('distance_unit', this.advancedSettings.distance_unit);
      formData.append('date_format', this.advancedSettings.date_format);
      formData.append('drivings_mandatory', this.advancedSettings.drivings_mandatory ? 1 : 0);
      formData.append('drivings_paid', this.advancedSettings.drivings_paid ? 1 : 0);
      formData.append('emails_enabled', this.advancedSettings.emails_enabled ? 1 : 0);
      formData.append('passenger_reviews_enabled', this.advancedSettings.passenger_reviews_enabled ? 1 : 0);
      formData.append('email_for_notifications', this.advancedSettings.email_for_notifications || '');
      formData.append('vat_percentage', this.advancedSettings.vat_percentage || '');
      formData.append('law_article', this.advancedSettings.law_article || '');
      formData.append('invoice_payment_instructions', this.advancedSettings.invoice_payment_instructions || '');

      return formData;
    },

    async save() {
      this.loading = true;
      let formData = this.createFormData();

      await this.$store
        .dispatch('articlesOfLaw/saveArticlesOfLaw', formData)
        .then((res) => {
          if (res.data.gnet_id) {
            this.gnetExists = true;
          }

          this.updateAdvancedSettings(res.data);

          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async saveGnetKey() {
      this.loading2 = true;
      this.gnetExists = true;

      await this.$store
        .dispatch('gnet/saveGnetKey', { gnet_id: this.advancedSettings.gnet_id })
        .then(() => {
          this.gnetIdValid = true;

          const user = this.$store.getters['auth/user'];

          const updatedUser = {
            ...user,
            organisation: {
              ...user.organisation,
              organisation_settings: {
                ...user.organisation.organisation_settings,
                gnet_id: this.advancedSettings.gnet_id,
              },
            },
          };
          this.$store.dispatch('auth/setUser', updatedUser);
          localStorage.setItem('LimoExpressUser', JSON.stringify(updatedUser));

          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });
        })
        .catch((error) => {
          this.gnetIdValid = false;
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.loading2 = false;
        });
    },

    async checkIfGnetIsValid() {
      await this.$store
        .dispatch('gnet/checkHealth')
        .then((res) => {
          if (res.data === false) {
            this.gnetIdValid = false;
          } else {
            this.gnetIdValid = true;
          }
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        });
    },

    async toggleStripeConnect() {
      this.loading3 = true;
      let path;

      if (this.advancedSettings.stripe_connect_enabled) {
        path = 'organisations/disableStripeConnect';
      } else {
        path = 'organisations/enableStripeConnect';
      }

      await this.$store
        .dispatch(path)
        .then((res) => {
          if (res?.success) {
            if (this.advancedSettings.stripe_connect_enabled) {
              this.advancedSettings = { ...this.advancedSettings, stripe_connect_enabled: 0 };

              const user = this.$store.getters['auth/user'];

              const updatedUser = {
                ...user,
                organisation: {
                  ...user.organisation,
                  organisation_settings: {
                    ...user.organisation.organisation_settings,
                    stripe_connect_enabled: 0,
                  },
                },
              };

              this.$store.dispatch('auth/setUser', updatedUser);
              localStorage.setItem('LimoExpressUser', JSON.stringify(updatedUser));

              this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.stripeDisabled'), color: 'green' });
            } else {
              window.open(res.data, '_blank');
            }
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.loading3 = false;
        });
    },

    updateAdvancedSettings(data) {
      const user = this.$store.getters['auth/user'];

      const updatedUser = {
        ...user,
        organisation: {
          ...user.organisation,
          organisation_settings: {
            ...user.organisation.organisation_settings,
            distance_unit: data.distance_unit,
            date_format: data.date_format,
            drivings_mandatory: data.drivings_mandatory,
            drivings_paid: data.drivings_paid,
            emails_enabled: data.emails_enabled,
            passenger_reviews_enabled: data.passenger_reviews_enabled,
            stripe_connect_enabled: data.stripe_connect_enabled,
          },
        },
      };

      this.$store.dispatch('auth/setUser', updatedUser);
      localStorage.setItem('LimoExpressUser', JSON.stringify(updatedUser));
    },
  },
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.integration-routes-text {
  display: flex;
  align-items: baseline;
  font-size: 16px;
}

.v-card {
  height: 100%;
}

.gnet-wrapper {
  position: relative;

  i {
    position: absolute;
    right: 20px;
    top: 40%;
    transform: translateY(-40%);
  }
}
</style>
